<template>
        <div class="input-control-wrapper section-deal--black" style="margin-bottom: 20px;">
                <div class="input-control input-control--number input-control--dark input-control--text-left">
                        <span class="input-control__label">{{ mainlabel }}</span>
                        <button class="input-control__button" @click="handleLeftClick">-</button>
                        <input type="text" class="input-control__input" style="text-align: center;" :value="showValue">
                        <button class="input-control__button" @click="handleRightClick">+</button>
                        <span v-if="sublabel != ''" class="input-control__label__switch">{{ sublabel }}</span>
                </div>
        </div>
</template>

<script>
export default {
        name: 'indicator-value-input',
        data () {
                return {
                        value: null,
                }
        },
        props: {
                index: {
                        type: Number,
                        default: 0
                },
                mainlabel: {
                        type: String,
                        default: ''
                },
                sublabel: {
                        type: String,
                        default: ''
                },
                min: {
                        type: Number,
                        default: 1
                },
                max: {
                        type: Number,
                        default: 1
                },
                default: {
                        type: Number,
                        default: 1
                },
                interval: {
                        type: Number,
                        default: 1
                },
                suffix: {
                        type: String,
                        default: ''
                }
        },
        computed: {
                showValue () {
                        if (this.value == null) {
                                this.value = this.default
                        }
                        return this.formatNumber(this.value) + this.suffix
                }
        },
        methods: {
                formatNumber (input) {
                        if (typeof input === "number" || !isNaN(input)) {
                                // 转换为数字
                                const num = parseFloat(input)
                                // 判断是否有小数
                                if (num % 1 === 0) {
                                        return num // 无小数，返回整数部分
                                } else {
                                        return parseFloat(num.toFixed(2)) // 有小数，保留两位
                                }
                        } else {
                                throw new Error("输入必须是字符或数字")
                        }
                },
                updatreCurrentValue (val) {
                        this.value = val
                },
                handleLeftClick () {
                        console.log(this.value, this.interval)

                        if (this.value - this.interval >= this.min) {
                                this.value -= this.interval
                        }
                        else {
                                return
                        }
                        this.$emit('inputValueChange', { index: this.index, value: this.formatNumber(this.value) })
                },
                handleRightClick () {
                        if (this.value + this.interval <= this.max) {
                                this.value += this.interval
                        }
                        else {
                                return
                        }
                        this.$emit('inputValueChange', { index: this.index, value: this.formatNumber(this.value) })
                }
        }
}
</script>