<template>
    <div class="panel-indicators__single-field">
        <label for="middle-887053945099" class="input-control input-control--line-params input-control--dark">
            <div class="input-control__preview">
                <div class="input-control__preview-preset" :style="selectColor" ref="currentColor"></div>
                <div>
                    <div class="input-control__preview-label">{{ label }}</div>
                    <input type="range" id="middle-887053945099" min="1" max="10" :value="_value"
                        class="input-control__slider" v-if="hasvalue" @change="handleSizeChange()" ref="inputSize">
                </div>
            </div>
            <div class="input-control__palette">
                <div class="input-control__palette-preset" :class="{ active: index == currentSelectIndex }"
                    v-for="(item, index) in colorList" :key="index" :style="assemblyStyle(item)"
                    @click="handleColorSelect(item, index)">
                </div>
            </div>
        </label>
    </div>
</template>

<script>
export default {
    name: 'indicator-color-select',
    data () {
        return {
            colorList: [
                'rgb(224, 85, 7)',
                'rgb(226, 149, 0)',
                'rgb(226, 204, 0)',
                'rgb(53, 203, 1)',
                'rgb(0, 223, 210)',
                'rgb(14, 139, 255)',
                'rgb(102, 117, 255)',
                'rgb(179, 102, 255)',
                'rgb(208, 74, 195)',
                'rgb(209, 57, 57)'
            ],
            rangeValue: 1,
            currentSelectIndex: -1,
            selectColor: ""
        }
    },
    props: {
        _key: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        color: {
            type: String,
            default: 'rgb(64, 64, 64)'
        },
        _value: {
            type: Number,
            default: 1
        },
        hasvalue: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        assemblyStyle (val) {
            return 'background-color:' + val
        },
        currentStyle (color) {
            this.selectColor = 'background-color:' + color
        },
        rangeChange (event) {
            this.$emit('rangeChange')
        },
        handleColorSelect (item, index) {
            this.currentSelectIndex = index
            this.$emit('colorValueChange', { key: this._key, color: item })
            this.currentStyle(item)
        },
        handleSizeChange () {
            this.$emit('sizeValueChange', { key: this._key, size: this.$refs.inputSize.value })
        }
    }
}
</script>