<template>
  <div class="app app--fixed animate app--sidebar_name-open"
    :class="[(open || setting != '') ? 'app--sidepanel-open' : '']">
    <aside class="sidebar app__sidebar">
      <div>
        <svg class="icon-burger-dark sidebar__toggle">
          <use xlink:href="../../assets/svg/spritemap.svg#icon-burger-dark"></use>
        </svg>
      </div>
      <nav class="sidebar__buttons">
        <a @click="goto('/home')" class="sidebar__button open-name" :class="{ active: curryPath == '/home' }">
          <div class="sidebar__button-icon">
            <svg class="icon-trade-sidebar">
              <use xlink:href="../../assets/svg/spritemap.svg#icon-trade-sidebar"></use>
            </svg>
            <span>{{ $t('Trade') }}</span>
          </div>
        </a>
        <button class="sidebar__button open-name" :class="{ active: curryPath == '/help' }" @click="goto('/help')">
          <div class="sidebar__button-icon">
            <svg class="icon-help">
              <use xlink:href="../../assets/svg/spritemap.svg#icon-help"></use>
            </svg>
            <span>{{ $t('Support') }}</span>
          </div>
        </button>
        <a class="sidebar__button open-name" :class="{ active: curryPath == '/account' }" @click="goto('/account')">
          <div class="sidebar__button-icon">
            <svg class="icon-profile">
              <use xlink:href="../../assets/svg/spritemap.svg#icon-profile"></use>
            </svg>
            <span>account</span>
          </div>
        </a>
        <a class="sidebar__button open-name" :class="{ active: curryPath == '/tournaments' }"
          @click="goto('/tournaments')">
          <div class="sidebar__button-icon">
            <div class="sidebar__button-icon-tournament">4</div>
            <svg class="icon-tournaments">
              <use xlink:href="../../assets/svg/spritemap.svg#icon-tournaments"></use>
            </svg>
            <span>Tourna-ments</span>
          </div>
        </a>
        <a class="sidebar__button open-name" :class="{ active: curryPath == '/account?active=Market' }"
          @click="goto('/account?active=Market')">
          <div class="sidebar__button-icon">
            <svg class="icon-market">
              <use xlink:href="../../assets/svg/spritemap.svg#icon-market"></use>
            </svg>
            <span>{{ $t('Market') }}</span>
          </div>
        </a>
        <button class="sidebar__button open-name" @click="openLeft">
          <div class="sidebar__button-icon">
            <svg class="icon-more-sidebar">
              <use xlink:href="../../assets/svg/spritemap.svg#icon-more-sidebar"></use>
            </svg>
            <span>{{ $t('More') }}</span>
          </div>
        </button>
      </nav>
      <div class="sidebar__usermenu">
        <div class="sidebar__usermenu-head">891631422@qq.com</div>
        <nav class="sidebar__list-menu">
          <a class="sidebar__list-menu__link">{{ $t('Payment') }}</a>
          <a class="sidebar__list-menu__link">{{ $t('Payouts') }}</a>
          <a class="sidebar__list-menu__link">{{ $t('Transactions') }}</a>
          <a class="sidebar__list-menu__link active">{{ $t('Trade') }}</a>
          <a class="sidebar__list-menu__link">{{ $t('Profile') }}</a>
        </nav>
      </div>
      <div class="sidebar__footer sidebar__footer--closed">
        <div class="sidebar__settings-block">
          <button class="sidebar__settings-button">
            <svg class="icon-settings-full-screen">
              <use xlink:href="../../assets/svg/spritemap.svg#icon-settings-full-screen"></use>
            </svg>
          </button>
          <button class="sidebar__settings-button">
            <svg class="icon-left-sidebar">
              <use xlink:href="../../assets/svg/spritemap.svg#icon-left-sidebar"></use>
            </svg>
          </button>
        </div>
        <div class="sidebar__settings">
          <button class="sidebar__settings-button" @click="openSetting">
            <svg class="icon-settings">
              <use xlink:href="../../assets/svg/spritemap.svg#icon-settings"></use>
            </svg>
          </button>
          <button class="sidebar__settings-button">
            <svg class="icon-settings-sound">
              <use xlink:href="../../assets/svg/spritemap.svg#icon-settings-sound"></use>
            </svg>
          </button>
        </div>
        <button class="sidebar__social-media">
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.1667 8.37424C15.1667 7.19296 16.1244 6.23535 17.3056 6.23535H25.8612C27.0425 6.23535 28.0001 7.19296 28.0001 8.37424V16.9298C28.0001 18.1111 27.0425 19.0687 25.8612 19.0687H17.3056C16.1244 19.0687 15.1667 18.1111 15.1667 16.9298V8.37424Z"
              fill="white"></path>
            <path
              d="M21.5833 17.9991C24.5365 17.9991 26.9305 15.6051 26.9305 12.6519C26.9305 9.69872 24.5365 7.30469 21.5833 7.30469C18.6301 7.30469 16.2361 9.69872 16.2361 12.6519C16.2361 15.6051 18.6301 17.9991 21.5833 17.9991Z"
              fill="#026FD3"></path>
            <path
              d="M24.252 10.4402C24.2978 10.1326 24.0163 9.88973 23.753 10.0098L18.5091 12.4019C18.3203 12.488 18.3341 12.7852 18.5299 12.8499L19.6113 13.2077C19.8178 13.276 20.0412 13.2407 20.2215 13.1113L22.6596 11.3613C22.7331 11.3085 22.8133 11.4171 22.7505 11.4844L20.9954 13.3643C20.8252 13.5467 20.859 13.8557 21.0638 13.9891L23.0287 15.2693C23.2491 15.4129 23.5326 15.2687 23.5738 14.992L24.252 10.4402Z"
              fill="white"></path>
            <path
              d="M11.0834 2.73535H2.91675C1.95025 2.73535 1.16675 3.51885 1.16675 4.48535V12.652C1.16675 13.6185 1.95025 14.402 2.91675 14.402H11.0834C12.0499 14.402 12.8334 13.6185 12.8334 12.652V4.48535C12.8334 3.51885 12.0499 2.73535 11.0834 2.73535Z"
              fill="#026FD3"></path>
            <path
              d="M9.26953 10.255L9.5293 8.56885H7.91146V7.4751C7.91146 7.01481 8.13704 6.56364 8.86165 6.56364H9.59766V5.12809C9.59766 5.12809 8.93001 5.01416 8.29199 5.01416C6.95898 5.01416 6.08854 5.8208 6.08854 7.28369V8.56885H4.60742V10.255H6.08854V14.4022H7.91146V10.255H9.26953Z"
              fill="white"></path>
            <path
              d="M7 15.0688C7 13.412 8.34315 12.0688 10 12.0688H18C19.6569 12.0688 21 13.412 21 15.0688V23.0688C21 24.7257 19.6569 26.0688 18 26.0688H10C8.34315 26.0688 7 24.7257 7 23.0688V15.0688Z"
              fill="#F14D5B"></path>
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M12 14.0688H16C17.6569 14.0688 19 15.412 19 17.0688V21.0688C19 22.7257 17.6569 24.0688 16 24.0688H12C10.3431 24.0688 9 22.7257 9 21.0688V17.0688C9 15.412 10.3431 14.0688 12 14.0688ZM12 15.0688C10.8954 15.0688 10 15.9643 10 17.0688V21.0688C10 22.1734 10.8954 23.0688 12 23.0688H16C17.1046 23.0688 18 22.1734 18 21.0688V17.0688C18 15.9643 17.1046 15.0688 16 15.0688H12ZM14 21.5688C12.6193 21.5688 11.5 20.4496 11.5 19.0688C11.5 17.6881 12.6193 16.5688 14 16.5688C15.3807 16.5688 16.5 17.6881 16.5 19.0688C16.5 20.4496 15.3807 21.5688 14 21.5688ZM14 20.5688C14.8284 20.5688 15.5 19.8973 15.5 19.0688C15.5 18.2404 14.8284 17.5688 14 17.5688C13.1716 17.5688 12.5 18.2404 12.5 19.0688C12.5 19.8973 13.1716 20.5688 14 20.5688ZM16.5 17.0688C16.2239 17.0688 16 16.845 16 16.5688C16 16.2927 16.2239 16.0688 16.5 16.0688C16.7761 16.0688 17 16.2927 17 16.5688C17 16.845 16.7761 17.0688 16.5 17.0688Z"
              fill="white"></path>
          </svg>
          {{ $t('Join us!').replace('!', '') }}
        </button>
        <div class="sidebar__livechat">
          <a class="sidebar__livechat-status online" href="https://qxbroker.com/zh/support/create">{{ $t('Help') }}</a>
        </div>
      </div>
      <div class="sidebar__footer sidebar__footer--open" dir="auto">
        <nav class="sidebar__list-menu sidebar__list-menu--tiny">
          <a class="list-menu__link">{{ $t('About us') }}</a>
          <a class="sidebar__list-menu__link">{{ $t('Support') }}</a>
        </nav>
        <a class="sidebar__logout">
          <svg class="icon-logout">
            <use xlink:href="../../assets/svg/spritemap.svg#icon-logout"></use>
          </svg>
          {{ $t('Logout') }}
        </a>
      </div>
    </aside>
    <aside class="sidepanel app__sidepanel sidepanel__bg-black" :class="[open ? 'active' : '']">
      <div class="sidepanel__close sidepanel__bg-black" @click="closeSidepanel">
        <svg class="icon-close">
          <use xlink:href="../../assets/svg/spritemap.svg#icon-close"></use>
        </svg>
      </div>
      <div class="panel-menu" v-show="!showranking && !showtransaction">
        <div class="panel-menu__title panel-menu-more__title">{{ $t('More') }}</div>
        <div class="panel-menu__item" @click="goto('/account?active=Analytics')">
          <div class="panel-menu__item-top panel-menu-more__item">
            <svg class="icon-analytics panel-menu__item-icon">
              <use xlink:href="../../assets/svg/spritemap.svg#icon-analytics"></use>
            </svg>
            {{ $t('Analytics') }}
          </div>
          <svg class="icon-caret-right panel-menu__item-caret">
            <use xlink:href="../../assets/svg/spritemap.svg#icon-caret-right"></use>
          </svg>
        </div>
        <div class="panel-menu__item" @click="showRanking">
          <div class="panel-menu__item-top panel-menu-more__item">
            <svg class="icon-top panel-menu__item-icon">
              <use xlink:href="../../assets/svg/spritemap.svg#icon-top"></use>
            </svg>
            {{ $t('TOP') }}
          </div>
          <svg class="icon-caret-right panel-menu__item-caret">
            <use xlink:href="../../assets/svg/spritemap.svg#icon-caret-right"></use>
          </svg>
        </div>
        <div class="panel-menu__item" @click="showTransaction">
          <div class="panel-menu__item-top panel-menu-more__item">
            <svg class="icon-trading-signals panel-menu__item-icon">
              <use xlink:href="../../assets/svg/spritemap.svg#icon-trading-signals"></use>
            </svg>
            {{ $t('Signals') }}
          </div>
          <svg class="icon-caret-right panel-menu__item-caret">
            <use xlink:href="../../assets/svg/spritemap.svg#icon-caret-right"></use>
          </svg>
        </div>
      </div>
      <Ranking v-show="showranking" />
      <Transaction v-show="showtransaction" />
    </aside>
    <aside class="sidepanel app__sidepanel sidepanel__bg-black" :class="[sildeBarState != '' ? 'active' : '']">
      <div class="sidepanel__close sidepanel__bg-black" @click="closeSideBar">
        <svg class="icon-close">
          <use xlink:href="../../assets/svg/spritemap.svg#icon-close"></use>
        </svg>
      </div>
      <div class="sidepanel__content">
        <div v-if="setting == 'setting'" class="sidepanel__content-wrapper panel-settings">
          <div class="sidepanel__title">{{ $t('Settings') }}</div>
          <div class="sidepanel__body">
            <div class="panel-settings" dir="auto">
              <div class="panel-settings__field">
                <label class="input-control input-control--select input-control--dark"
                  :class="{ 'active': languageShow }">
                  <span class="input-control__label" dir="auto">{{ $t('Language') }}</span>
                  <input class="input-control__input" id="-942638841956" type="hidden" value="zh" />
                  <div class="input-control__value" @click="languageShow = true">
                    <svg class="flag flag-global">
                      <use xlink:href="../../assets/svg/flags.svg#flag-global"></use>
                    </svg>
                    {{ $store.state.lanageName }}
                  </div>
                  <div class="input-control__caret">
                    <svg class="icon-caret">
                      <use xlink:href="../../assets/svg/spritemap.svg#icon-caret"></use>
                    </svg>
                  </div>
                  <div class="input-control__dropdown">
                    <div class="input-control__dropdown-option" v-for="item in $store.state.langs"
                      :class="{ active: item.code == $store.state.activeLange }" @click="setLangs(item)">{{ item.name }}
                    </div>
                  </div>
                </label>
              </div>
              <div class="panel-settings__field">
                <label class="input-control input-control--select input-control--dark" for="-67789805625">
                  <span class="input-control__label" dir="auto">{{ $t('Timezone') }}</span>
                  <input class="input-control__input" id="-67789805625" type="hidden" value="28800" />
                  <div class="input-control__value">(UTC+08:00)</div>
                  <div class="input-control__caret">
                    <svg class="icon-caret">
                      <use xlink:href="../../assets/svg/spritemap.svg#icon-caret"></use>
                    </svg>
                  </div>
                </label>
              </div>
              <div class="panel-settings__section">
                <div class="sidepanel__section-caption sidepanel__section-caption--bright">{{ $t('Template') }}</div>
                <label class="panel-settings__them-switch" @click="setHtmlClass('light')">
                  <span class="panel-settings__them-slider" :class="{ active: className == 'light' }">
                    <div class="panel-settings__them-text">
                      <svg class="icon-light-mode">
                        <use xlink:href="../../assets/svg/spritemap.svg#icon-light-mode"></use>
                      </svg>
                      {{ $t('Light Mode') }}
                    </div>
                  </span>
                  <span><input type="radio" name="radio" /></span>
                </label>
                <label class="panel-settings__them-switch" @click="setHtmlClass('dark')">
                  <span class="panel-settings__them-slider" :class="{ active: className == 'dark' }">
                    <div class="panel-settings__them-text">
                      <svg class="icon-twilight-mode">
                        <use xlink:href="../../assets/svg/spritemap.svg#icon-twilight-mode"></use>
                      </svg>
                      {{ $t('Twilight') }}
                    </div>
                  </span>
                  <span><input type="radio" name="radio" /></span>
                </label>
                <label class="panel-settings__them-switch" @click="setHtmlClass('black')">
                  <span class="panel-settings__them-slider" :class="{ active: className == 'black' }">
                    <div class="panel-settings__them-text">
                      <svg class="icon-night-mode">
                        <use xlink:href="../../assets/svg/spritemap.svg#icon-night-mode"></use>
                      </svg>
                      {{ $t('Full Night') }}
                    </div>
                  </span>
                  <span><input type="radio" name="radio" checked="" /></span>
                </label>
              </div>
              <div class="panel-settings__section" v-if="false">
                <div class="sidepanel__section-caption sidepanel__section-caption--bright">{{ $t('Platform') }}</div>
                <div class="panel-settings__field">
                  <label class="input-control input-control--number input-control--dark" for="-177902024298">
                    <span class="input-control__label" dir="auto">{{ $t(`Grid's opacity`) }}</span>
                    <button class="input-control__button">-</button>
                    <input class="input-control__input" type="text" id="-177902024298" value="8" />
                    <button class="input-control__button">+</button>
                  </label>
                </div>
                <div class="panel-settings__field">
                  <div class="input-control-wrapper">
                    <label class="input-control input-control--checkbox input-control--dark" for="-709773301165">
                      <input class="input-control__checkbox" id="-709773301165" type="checkbox" checked="" />
                      <span class="input-control__label input-control__label--accent" dir="auto">自动滚动</span>
                    </label>
                    <div class="input-control__help">自动滚动图表</div>
                  </div>
                </div>
                <div class="panel-settings__field">
                  <div class="input-control-wrapper">
                    <label class="input-control input-control--checkbox input-control--dark" for="-837890576596">
                      <input class="input-control__checkbox" id="-837890576596" type="checkbox" checked="" />
                      <span class="input-control__label input-control__label--accent" dir="auto">一键交易</span>
                    </label>
                    <div class="input-control__help">未确认的进行交易</div>
                  </div>
                </div>
                <div class="panel-settings__field">
                  <div class="input-control-wrapper">
                    <label class="input-control input-control--checkbox input-control--dark" for="-71383813653">
                      <input class="input-control__checkbox" id="-71383813653" type="checkbox" checked="" />
                      <span class="input-control__label input-control__label--accent" dir="auto">运作能力模式</span>
                    </label>
                    <div class="input-control__help">对图表和蜡烛使用优化的渲染</div>
                  </div>
                </div>
                <div class="panel-settings__field">
                  <div class="input-control-wrapper">
                    <label class="input-control input-control--checkbox input-control--dark"
                      for="shortorderlabel-486606681777">
                      <input class="input-control__checkbox" id="shortorderlabel-486606681777" type="checkbox"
                        checked="" />
                      <span class="input-control__label input-control__label--accent" dir="auto">Short order
                        label</span>
                    </label>
                    <div class="input-control__help">Use short order element mode</div>
                  </div>
                </div>
              </div>
              <div class="panel-settings__section" v-if="false">
                <div class="sidepanel__section-caption sidepanel__section-caption--bright">图表颜色</div>
                <div class="input-color">
                  <div class="input-color__label">
                    <div class="input-color__preview" style="background-color: rgb(15, 175, 89)"></div>
                    上升趋势
                  </div>
                  <div class="input-color__list">
                    <div class="input-color__list-item" style="background-color: rgb(53, 203, 1)"></div>
                    <div class="input-color__list-item" style="background-color: rgb(15, 175, 89)">
                      <svg class="icon-check-white">
                        <use xlink:href="../../assets/svg/spritemap.svg#icon-check-white"></use>
                      </svg>
                    </div>
                    <div class="input-color__list-item" style="background-color: rgb(0, 195, 255)"></div>
                    <div class="input-color__list-item" style="background-color: rgb(123, 255, 247)"></div>
                    <div class="input-color__list-item" style="background-color: rgb(255, 255, 255)"></div>
                  </div>
                </div>
                <div class="input-color">
                  <div class="input-color__label">
                    <div class="input-color__preview" style="background-color: rgb(255, 98, 81)"></div>
                    下降趋势
                  </div>
                  <div class="input-color__list">
                    <div class="input-color__list-item" style="background-color: rgb(255, 98, 81)">
                      <svg class="icon-check-white">
                        <use xlink:href="../../assets/svg/spritemap.svg#icon-check-white"></use>
                      </svg>
                    </div>
                    <div class="input-color__list-item" style="background-color: rgb(219, 70, 53)"></div>
                    <div class="input-color__list-item" style="background-color: rgb(255, 194, 77)"></div>
                    <div class="input-color__list-item" style="background-color: rgb(226, 113, 255)"></div>
                    <div class="input-color__list-item" style="background-color: rgb(0, 0, 0)"></div>
                  </div>
                </div>
              </div>
              <div class="panel-settings__section">
                <div class="sidepanel__section-caption sidepanel__section-caption--bright">Background</div>
                <label class="panel-settings__background-wrapper">
                  <input class="panel-settings__background-input" type="file" />
                  <div class="panel-settings__background">
                    <svg class="icon-bg-image-settings-graph panel-settings__background-icon">
                      <use xlink:href="../../assets/svg/spritemap.svg#icon-bg-image-settings-graph"></use>
                    </svg>
                    <div>
                      <div class="panel-settings__background-title">Choose file</div>
                      <div class="panel-settings__background-subtitle">(Max size — 2 Mb)</div>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="setting == 'draw'" class="sidepanel__content-wrapper">
          <div class="sidepanel__title">绘图</div>
          <div class="sidepanel__body sidepanel__body--scroll-shadow">
            <div class="sidepanel__section">
              <div class="sidepanel__section-caption">绘图</div>
              <ul class="sidepanel__menu">
                <li class="sidepanel__menu-item" @click="drawItemClick('draw_arc_fill')">弧</li>
                <li class="sidepanel__menu-item" @click="drawItemClick('draw_cross')">正交线</li>
                <li class="sidepanel__menu-item" @click="drawItemClick('draw_arc')">曲线</li>
                <li class="sidepanel__menu-item" @click="drawItemClick('draw_loopline')">循环线</li>
                <li class="sidepanel__menu-item" @click="drawItemClick('draw_timerange')">日期范围</li>
                <li class="sidepanel__menu-item" @click="drawItemClick('draw_time_and_price_range')">日期和价格范围</li>
                <li class="sidepanel__menu-item" @click="drawItemClick('draw_non_intersect')">不相交的通道</li>
                <li class="sidepanel__menu-item" @click="drawItemClick('draw_extended_line')">延长线</li>
                <li class="sidepanel__menu-item" @click="drawItemClick('draw_fibonacciLine_sector')">菲波纳奇扇形线</li>
                <li class="sidepanel__menu-item" @click="drawItemClick('draw_fibonacciLine_callback')">斐波那契回调线</li>
                <li class="sidepanel__menu-item" @click="drawItemClick('draw_horizontal_top_bottom_rect')">平顶/底</li>
                <li class="sidepanel__menu-item" @click="drawItemClick('draw_gann_box')">Gann Box</li>
                <li class="sidepanel__menu-item" @click="drawItemClick('draw_horizontal_line')">水平线</li>
                <li class="sidepanel__menu-item" @click="drawItemClick('draw_parallel_line')">并行通道</li>
                <li class="sidepanel__menu-item" @click="drawItemClick('draw_pitchfan')">Pitchfan</li>
                <li class="sidepanel__menu-item" @click="drawItemClick('draw_pitchfork')">Pitchfork</li>
                <li class="sidepanel__menu-item" @click="drawItemClick('draw_price_range')">价格范围</li>
                <li class="sidepanel__menu-item" @click="drawItemClick('draw_ray_line')">射线</li>
                <li class="sidepanel__menu-item" @click="drawItemClick('draw_rect')">长方形</li>
                <li class="sidepanel__menu-item" @click="drawItemClick('draw_trend_angle')">趋势角度</li>
                <li class="sidepanel__menu-item" @click="drawItemClick('draw_trend_line')">趋势线</li>
                <li class="sidepanel__menu-item" @click="drawItemClick('draw_triangle')">三角形</li>
                <li class="sidepanel__menu-item" @click="drawItemClick('draw_vertical_line')">垂直线</li>
              </ul>
            </div>
          </div>
          <div class="sidepanel__footer"><button @click="drawClearAllClick"
              class="button button--danger button--small button--dark sidepanel__button"><svg class="icon-recycle">
                <use xlink:href="/profile/images/spritemap.svg#icon-recycle"></use>
              </svg><span>全部删除</span></button></div>
        </div>
        <div v-else-if="setting == 'norm'" class="sidepanel__content-wrapper">
          <div class="sidepanel__title">指标</div>
          <div class="sidepanel__body sidepanel__body--scroll-shadow">
            <div class="sidepanel__section">
              <div class="sidepanel__section-caption">主图区域</div>
              <ul class="sidepanel__menu">
                <li class="sidepanel__menu-item" v-if="isMainArea(item.name)" v-for="(item, index) in indicatorList"
                  :key="index" @click="normItemClick(item, true)">{{ item.shortName }}</li>
              </ul>
            </div>
            <div class="sidepanel__section">
              <div class="sidepanel__section-caption">副图区域</div>
              <ul class="sidepanel__menu">
                <li class="sidepanel__menu-item" v-if="!isMainArea(item.name)" v-for="(item, index) in indicatorList"
                  :key="index" @click="normItemClick(item, false)">{{ item.shortName }}</li>
              </ul>
            </div>
          </div>
          <div class="sidepanel__footer"><button @click="normClearAllClick"
              class="button button--danger button--small button--dark sidepanel__button"><svg class="icon-recycle">
                <use xlink:href="/profile/images/spritemap.svg#icon-recycle"></use>
              </svg><span>全部删除</span></button></div>
        </div>
        <!--indicator param pane-->
        <IndicatorParamPanel :obj="indicator" :defaultStyles="defaultIndicatorStyles.indicator"
          @remove="normRemoveClick" @closePanel="indicatorParamPanelClose" v-if="indicatorSelected">
        </IndicatorParamPanel>
      </div>
    </aside>
    <div class="page app__page">
      <Header />
      <router-view @addIndicator="handleAddIndicator" :candleAreaIndicatorList="candleAreaIndicatorList" />
    </div>
    <nav class="navbar app__navbar"><a aria-current="page" class="navbar__item active active" href="/zh/trade"><svg
          class="icon-nav-trade-new">
          <use xlink:href="../../assets/svg/spritemap.svg#icon-nav-trade-new"></use>
        </svg></a>
      <div class="navbar__block"><button class="navbar__item"><svg class="icon-nav-help">
            <use xlink:href="../../assets/svg/spritemap.svg#icon-nav-help"></use>
          </svg></button></div><a class="navbar__item" href="/zh/settings"><svg class="icon-nav-profile">
          <use xlink:href="../../assets/svg/spritemap.svg#icon-nav-profile"></use>
        </svg></a>
      <div class="navbar__block">
        <div class="navbar__count blue">4</div><a class="navbar__item" href="/zh/tournaments"><svg
            class="icon-nav-tournament">
            <use xlink:href="../../assets/svg/spritemap.svg#icon-nav-tournament"></use>
          </svg></a>
      </div>
      <div class="navbar__block"><button class="navbar__item"><svg class="icon-nav-more">
            <use xlink:href="../../assets/svg/spritemap.svg#icon-nav-more"></use>
          </svg></button></div>
    </nav>
    <div class="app__backdrop"></div>
  </div>
</template>
<script>
import Header from '../../components/header.vue'
import Ranking from './components/ranking.vue'
import Transaction from './components/transaction.vue'
import { EventBus } from '../../components/eventBus.js'
import IndicatorParamPanel from './components/kline-indicator-parampane.vue'

export default {
  data () {
    return {
      active: 1,
      // menu: [
      //   {
      //     id: 0,
      //     name: '交易',
      //     path: '/',
      //   },
      //   {
      //     id: 1,
      //     name: '客服',
      //     path: '/',
      //   },
      //   {
      //     id: 2,
      //     name: 'account',
      //     path: '/',
      //   },
      //   {
      //     id: 3,
      //     name: 'Tourna-ments',
      //     path: '/',
      //   },
      //   {
      //     id: 4,
      //     name: '市场',
      //     path: '/',
      //   },
      //   {
      //     id: 5,
      //     name: '更详细',
      //     path: '/',
      //   },
      // ],
      open: false,
      setting: '',
      indicator: null,
      languageShow: false,
      curryPath: '/',
      showranking: false,
      showtransaction: false,
      className: 'black',
      indicatorList: [],
      defaultIndicatorStyles: null,
      isMain: false,
      candleAreaIndicatorList: [
        "ALLIGATOR",
        'BOLL',
        'BBI',
        'EMV',
        'EMA',
        'MTM',
        'MA',
        'SMA'
      ]
    }
  },
  components: {
    Header,
    Ranking,
    Transaction,
    IndicatorParamPanel
  },
  created () {
    EventBus.$on('settingBarToIndex', (data) => {
      this.setting = data
      if (this.setting == '') {
        this.indicator = null
      }
    })
    EventBus.$on('indicatorList', (data) => {
      this.indicatorList = data
    })
    EventBus.$on('showIndicatorToolpane', (data) => {
      var indicator = this.indicatorList.findIndex(t => t.name == data)
      if (indicator > -1) {
        this.setting = 'norm'
        this.indicator = this.indicatorList[indicator]
      }
    })
    EventBus.$on('indicatorRemove', (data) => {
      var indicator = this.indicatorList.findIndex(t => t.name == data)
      if (indicator > -1) {
        if (this.indicator != null && this.indicator.name == this.indicatorList[indicator].name) {
          this.indicator = null
        }
        //this.indicatorList.splice(indicator, 1);
      }
    })
    EventBus.$on('defaultIndicatorStyles', (data) => {
      console.log(data)
      this.defaultIndicatorStyles = data
    })
  },
  mounted () {
    this.className = localStorage.className || 'black'
  },
  computed: {
    sildeBarState () {
      return this.setting
    },
    indicatorSelected () {
      if (this.indicator !== null) {
        this.isMain = this.indicator.inputLabel ? true : false
      }

      return this.indicator != null && this.isMain
    },
    showIndicator () {
      return this.indicatorList
    }
  },
  methods: {
    isMainArea (name) {
      return this.candleAreaIndicatorList.includes(name)
    },
    openSetting () {
      this.setting = 'setting'
    },
    setLangs (locale) {
      this.$i18n.locale = locale.code
      this.$store.state.activeLange = locale.code
      localStorage.activeLange = locale.code
      this.$store.state.lanageName = locale.name
      this.languageShow = false

    },
    openLeft () {
      this.open = !this.open
    },
    closeSidepanel () {
      this.openLeft()
      this.showranking = false
      this.showtransaction = false
    },
    goto (path) {
      this.open = false
      this.$router.push(path)
    },
    tabsHandle (action) {
      this.active = action
    },
    searchHandle () {
      this.$refs.Header.showSearch = true
    },
    showRanking () {
      this.showranking = !this.showranking
    },
    showTransaction () {
      this.showtransaction = !this.showtransaction
    },
    setHtmlClass (className) {
      document.documentElement.setAttribute('class', className)
      this.className = className
      localStorage.className = className
    },
    closeSideBar () {
      EventBus.$emit('settingBarToKline', this.setting)
      this.setting = ''
      this.indicator = null
    },
    drawItemClick (item) {
      EventBus.$emit('drawItemSelect', item)
    },
    drawClearAllClick () {
      EventBus.$emit('drawClearAll', '')
    },
    normItemClick (item, isMainArea) {
      //this.indicator = item;
      this.isMain = isMainArea
      console.log(item, isMainArea)
      EventBus.$emit('normItemSelect', { item: item, area: isMainArea })
    },
    normClearAllClick () {
      EventBus.$emit('normClearAll', '')
    },
    normRemoveClick (name) {
      EventBus.$emit('normRemoveOne', name)
    },
    indicatorParamPanelClose () {
      this.indicator = null
    },
    handleAddIndicator (data) {
      this.indicator = data
    }
  },
  watch: {
    $route: {
      handler (val) {
        // this.curryPath = val.path
        this.curryPath = val.fullPath
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>
<style scoped>
@import url('../../assets/css/home.css');
</style>
